<template>
  <div>
    <Row class="p-t-5 p-l-20 p-r-20" :gutter="50">
      <i-col span="14">
        <h2 class="p-b-10">品牌完成率</h2>

        <Row class="table-title p-l-10 p-r-10">
          <i-col span="9">品牌</i-col>
          <i-col span="5" class="text-center">数量</i-col>
          <i-col span="5" class="text-center">及时完成率</i-col>
          <i-col span="5" class="text-center">合格率</i-col>
        </Row>
        <Row v-for="(item,index) in brandCompleteData" :key="'b'+index" class="p-t-5 p-l-10 p-r-10">
          <i-col span="9">{{item.brandName}}</i-col>
          <i-col span="5" class="text-center">{{item.itemNumber}}</i-col>
          <i-col span="5" class="text-center">{{item.onTimeRate}} %</i-col>
          <i-col span="5" class="text-center">{{item.onTestRate}} %</i-col>
        </Row>
        <div class="paging_style">
          <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
        </div>
      </i-col>
      <i-col span="10">
        <h2 class="p-b-10">站点运维情况TOP5</h2>

        <RadioGroup v-model="chooseMaintainTopType" size="small" type="button" button-style="solid">
            <Radio :label="1">故障率</Radio>
            <Radio :label="2">修复率</Radio>
            <Radio :label="3">作业完成率</Radio>
        </RadioGroup>
        <Row class="table-title p-l-10 p-r-10 m-t-5">
          <i-col span="16">站点名称</i-col>
          <i-col span="8" class="text-center">比率</i-col>
        </Row>
        <Row v-for="(item,index) in maintainTopData" :key="'m'+index" class="p-t-5 p-l-10 p-r-10">
          <i-col span="16">{{item.name}}</i-col>
          <i-col span="8" class="text-center">{{item.rate}} %</i-col>
        </Row>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { countTaskItemGbGrand, getPublisherMaintainTopRateByType } from '@/api/dw/maintain'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      brandCompleteData: [],
      showArray: [],
      currentPageSize: 8,
      currentPageNumber: 1,

      chooseMaintainTopType: 1,
      maintainTopData: []
    }
  },
  mounted () {
    this.loadBrandCompleteData()
    this.loadMaintainTopData()
  },
  methods: {
    loadBrandCompleteData () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        assetId: this.assetId,
        companyId: this.companyId
      }

      countTaskItemGbGrand(queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.brandCompleteData = newArray
    },
    loadMaintainTopData () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        assetId: this.assetId,
        maintainRateType: this.chooseMaintainTopType
      }

      getPublisherMaintainTopRateByType(queryModel).then(res => {
        this.maintainTopData = res.splice(0, 5)
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    assetId () {
      return this.$store.state.situationWeb.assetId
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadBrandCompleteData()
        this.loadMaintainTopData()
      }
    },
    companyId (val) {
      this.loadBrandCompleteData()
      this.loadMaintainTopData()
    },
    assetId (val) {
      this.loadBrandCompleteData()
      this.loadMaintainTopData()
    },
    chooseMaintainTopType (val) {
      this.loadMaintainTopData()
    }
  }
}
</script>
